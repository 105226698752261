import React, { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { use100vh } from "react-div-100vh";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

// Context
import { StoreContext } from "../../../components/context/store";
import { CurrentLanguage } from "../../../components/context/current-language";

// Components
import { Layout } from "../../../components/account/layout";
import { PageColor } from "../../../components/context/page-color";
import { HeaderColor } from "../../../components/context/header-color";

// Layout
import {
  AccountNavigation,
  Page,
} from "../../../components/account/layout-components";

// SEO
import { PageSeo } from "../../../components/global/page-seo";

const CUSTOMER = gql`
  query ($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      email
      tags
    }
  }
`;

const Article = styled.div`
  & .image-container {
    overflow: hidden;
    cursor: pointer;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      /* border: 39px solid #fff; */
      /* box-sizing: border-box; */

      /* @media (max-width: 999px) {
        border: 27px solid #fff;
      } */
    }
  }

  & .title-bar {
    display: flex;
    flex-direction: row;

    & p {
      margin: 0;
    }

    & .spacer {
      margin: 0 10px;
    }
  }

  & .text-container {
    margin: 30px 0 0 0;
    /* padding: 0 39px; */

    & .article-title {
      margin: 15px 0 20px 0;
    }

    & .article-excerpt {
      margin: 20px 0 15px 0;
    }

    /* @media (max-width: 999px) {
      padding: 0 27px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    } */
  }
`;

const Catalogues = () => {
  const { customerAccessToken } = useContext(StoreContext);

  const accessToken =
    customerAccessToken !== null ? customerAccessToken.accessToken : null;

  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  useEffect(() => {
    setPageColor(`#fff`);
    setHeaderColor(`#fff`);
  }, []);

  const { loading, error, data } = useQuery(CUSTOMER, {
    variables: { customerAccessToken: accessToken },
  });

  const accountData = useStaticQuery(graphql`
    query {
      prismicAccounts(lang: { eq: "fr-fr" }) {
        data {
          catalogues_text {
            richText
          }
          catalogues {
            catalogue_title {
              richText
            }
            catalogue_text {
              richText
            }
            file_download {
              url
            }
            catalogue_image {
              gatsbyImageData(aspectRatio: 0.71)
              alt
            }
          }
        }
      }
    }
  `);

  const cataloguesPacks = accountData.prismicAccounts.data.catalogues.map(
    (item, index) => (
      <Article className="single-article">
        <div className="image-container">
          <GatsbyImage
            image={item.catalogue_image.gatsbyImageData}
            alt={
              item.catalogue_image.alt !== null ? item.catalogue_image.alt : ``
            }
          />
        </div>

        <div className="text-container">
          <PrismicRichText
            field={item.catalogue_title.richText}
            components={{
              heading1: ({ children }) => (
                <h3 className="article-title">{children}</h3>
              ),
            }}
          />

          <PrismicRichText
            field={item.catalogue_text.richText}
            components={{
              paragraph: ({ children }) => (
                <p className="article-excerpt">{children}</p>
              ),
            }}
          />

          <a
            className="uppercase small"
            href={item.file_download.url}
            download
            target="_blank"
            rel="noopener"
          >
            {currentLanguage === `en` ? `Download` : `Voir Plus`}
          </a>
        </div>
      </Article>
    )
  );

  const height = use100vh();

  if (loading || error)
    return (
      <Layout>
        <PageSeo
          title={`Professionnel`}
          image={null}
          description={null}
          url={null}
        />

        <Page className="page logged-in" height={height}>
          <AccountNavigation data={data} />
        </Page>
      </Layout>
    );

  return (
    <Layout>
      <PageSeo
        title={`Professionnel`}
        image={null}
        description={null}
        url={null}
      />

      <Page className="page logged-in" height={height}>
        <AccountNavigation data={data} />

        <div className="content-container">
          <div className="inner-content-container">
            <div className="content">
              <h3>Professionnel</h3>

              <PrismicRichText
                field={
                  accountData.prismicAccounts.data.catalogues_text.richText
                }
              />

              <div className="grid">{cataloguesPacks}</div>
            </div>
          </div>
        </div>
      </Page>
    </Layout>
  );
};

export default Catalogues;
